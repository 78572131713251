/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useRef } from 'react';

export default function WebRTC({ selectedDrone, bottom = 20, right = 12 }) {
  const videoRef = useRef(null);
  const maxSize = '100%';
  const minSize = '20%';

  const videoSizeSmall = () => {
    if (videoRef.current) {
      videoRef.current.style.width = minSize;
      videoRef.current.style.minHeight = '';
      videoRef.current.className = `absolute bottom-${bottom} right-${right}`;
    }
  };

  const videoSizeBig = () => {
    if (videoRef.current) {
      videoRef.current.style.width = maxSize;
      videoRef.current.style.minHeight = maxSize;
      videoRef.current.className = 'absolute bottom-0';
    }
  };

  const showPlayer = () => {
    if (videoRef.current) {
      if (videoRef.current.style.width === maxSize) {
        videoSizeBig();
      } else {
        videoSizeSmall();
      }
    }
  };

  const hidePlayer = () => {
    if (videoRef.current) {
      videoRef.current.className = 'hidden';
      videoRef.current.src = null;
    }
  };

  useEffect(() => {
    if (selectedDrone) {
      const src = `${process.env.REACT_APP_STREAM_URL}/${selectedDrone ? selectedDrone.droneId : null}`;
      videoRef.current.src = src;
      const element = document.getElementById('video');
      if (element) {
        showPlayer();
      }
    } else {
      hidePlayer();
    }
  }, [selectedDrone]);

  return <iframe id="video" title="Livestream" ref={videoRef} src={null} />;
}
