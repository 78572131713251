import React from 'react';
import './App.scss';
import { Routes, Route, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useAuth } from 'react-oidc-context';
import 'react-toastify/dist/ReactToastify.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import SideNav from './SideNav/SideNav';
import Dashboard from '../../pages/Dashboard/Dashboard';
import Missions from '../../pages/Missions/Missions';
import CustomRouter from '../../custom/CustomRouter';
import CustomHistory from '../../custom/CustomHistory';
import ManualInspection from '../../pages/FlightReport/ManualInspection';
import FlightReportList from '../../pages/FlightReport/FlightReportList';
import AppStore from '../../pages/AppStore/AppStore';
import AppStoreDetails from '../../pages/AppStore/AppDetails/AppDetails';
import AutomaticChecks from '../../pages/AutomaticChecks/AutomaticChecks';
import ScheduleMission from '../../pages/ScheduleMission/ScheduleMission';
import TestPage from '../../pages/TestPage/TestPage';
import Users from '../../pages/Users/Users';
import DroneForm from '../../pages/Users/DroneForm';
import UserService from '../../services/Authentication/user.service';

export default function App() {
  return (
    <div className="app">
      <CustomRouter history={CustomHistory}>
        <AppContent />
      </CustomRouter>
      <ToastContainer />
    </div>
  );
}

function AppContent() {
  const location = useLocation();
  const auth = useAuth();
  const isAdmin = UserService.checkAdmin(auth.user);

  return (
    <>
      {location.pathname !== '/login' && location.pathname !== '/automaticchecks' && <SideNav />}
      <Routes>
        <Route exact path="/" element={<Dashboard />} />
        <Route path="/schedule" element={<ScheduleMission />} />
        <Route path="/automaticchecks" element={<AutomaticChecks isAdmin={isAdmin} />} />
        <Route path="/manualinspection" element={<ManualInspection />} />
        <Route path="/missions" element={<Missions />} />
        <Route path="/data" element={<FlightReportList />} />
        <Route path="/appstore" element={<AppStore />} />
        <Route path="/appstore/:appId" element={<AppStoreDetails />} />
        {isAdmin ? (
          <>
            <Route path="/testpage" element={<TestPage />} />
            <Route path="/users" element={<Users />} />
            <Route exact path="/drones/:id" element={<DroneForm />} />
          </>
        ) : null}
      </Routes>
    </>
  );
}
