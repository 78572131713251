import { useEffect, useState } from 'react';
import GenericService from '../services/generic.service';

const useDroneStatus = (droneId, statusType = 'health') => {
  const [status, setStatus] = useState(null);

  const fetchData = async () => {
    let promise: Promise = null;
    switch (statusType) {
      case 'health':
        promise = GenericService.DroneService.getDroneHealth(droneId);
        break;
      case 'location':
        promise = GenericService.DroneService.getDroneLocation(droneId);
        break;
      default:
        promise = GenericService.DroneService.getDroneStatus(droneId);
        break;
    }

    promise
      .then((data) => {
        if (data === '') {
          setStatus(null);
        } else {
          setStatus(data);
        }
      })
      .catch((error) => {
        console.log(error);
        setStatus(null);
      });
  };

  useEffect(() => {
    const interval = setInterval(() => fetchData(), 2000);
    fetchData();
    return () => clearInterval(interval);
  }, []);

  return { status };
};

export default useDroneStatus;
