import React, { useEffect, useState } from 'react';

import '../shared.scss';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { motion } from 'framer-motion';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import MapOverlay from './MapOverlay/MapOverlay';
import Map from '../../components/Map/DashboardMap/DashboardMap';
import GenericService from '../../services/generic.service';
import Notifications from '../../components/Notifications/Notifications';

export const DashboardContext = React.createContext(undefined);

export default function Dashboard() {
  const [drones, setDrones] = useState([]);
  const [selectedDrone, setSelectedDrone] = useState(null);
  const [mapStyle, setMapStyle] = useState(localStorage.getItem('mapStyle') ?? 'streets-v11');

  useEffect(() => {
    localStorage.setItem('mapStyle', mapStyle);
  }, [mapStyle]);

  const getDrones = () => {
    GenericService.DroneService.getCurrentUserDrones().then((response) => {
      setDrones(response);
    });
  };

  useEffect(() => {
    getDrones();
  }, []);

  return (
    <DashboardContext.Provider
      value={{
        mapStyle,
        setMapStyle,
        selectedDrone,
        setSelectedDrone,
      }}
    >
      <PageWrapper
        sidebar={
          <>
            <div className="heading">
              <h2>Latest activity</h2>
              <motion.div whileHover={{ scale: 1.05 }} transition={{ duration: 0.25 }} whileTap={{ scale: 0.75 }}>
                <FontAwesomeIcon className="clearAll" icon={solid('bars-staggered')} />
              </motion.div>
            </div>
            <Notifications />
          </>
        }
      >
        <div style={{ height: '100vh', width: '100%' }}>
          <Map mapStyle={mapStyle} drones={drones} />
          <MapOverlay drones={drones} />
        </div>
      </PageWrapper>
    </DashboardContext.Provider>
  );
}
