import { User } from 'oidc-client-ts';
import axios from 'axios';
import CustomHistory from '../custom/CustomHistory';

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});

function getUser() {
  const oidcStorage = localStorage.getItem(
    `oidc.user:${process.env.REACT_APP_OIDC_AUTHORITY}:${process.env.REACT_APP_OIDC_CLIENT_ID}`
  );
  if (!oidcStorage) {
    return null;
  }
  return User.fromStorageString(oidcStorage);
}

instance.interceptors.request.use(
  (config) => {
    const user = getUser();
    if (user && user.access_token) {
      const token = user?.access_token;
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

instance.interceptors.response.use(
  (res) => res,
  async (err) => {
    if (err.response) {
      if (err.response.status === 401) {
        CustomHistory.replace('/login');
      }
    }

    return Promise.reject(err);
  }
);

export default instance;
