/* eslint-disable */

import React, { useEffect, useState } from 'react';
import '../shared.scss';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import api from '../../services/api';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import Input from '../../components/Input/Input';
import Select from '../../components/Select/Select';
import droneService from '../../services/generic.service';

export default function UserForm() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [users, setUsers] = React.useState([]);
  const [drone, setDrone] = React.useState([]);

  const isNewDrone = id === 'new' ? true : false;

  var nameRef = React.useRef(null);
  var userRef = React.useRef(null);

  const fetchUsers = () => {
    api
      .get('/users')
      .then(({ data }) => {
        setUsers(data);
        userRef.current = data.find((u) => u.userId === drone.userId);
      })
      .catch((err) => {
        alert(err);
      });
  };
  const fetchDrone = (id) => {
    api.get(`/drones/${id}`).then(({ data }) => {
      delete data.user;
      setDrone(data);

      nameRef.current.value = data.name;
    });
  };

  React.useEffect(() => {
    if (!isNewDrone) {
      fetchDrone(id);
    }
  }, [id]);

  React.useEffect(() => {
    fetchUsers();
  }, []);

  const handleSubmit = (data) => {
    if (isNewDrone) {
      createDrone(data);
    } else {
      updateDrone(data);
    }
  };

  const createDrone = (drone) => {
    droneService.DroneService.createDrone(drone)
      .then(({ data }) => {
        navigate('/users');
        window.toast.success('The drone was updated successfully.');
      })
      .catch((reason) => window.toast.error(reason.title));
  };

  const updateDrone = (drone) => {
    droneService.DroneService.updateDrone(drone)
      .then(() => {
        navigate('/users');
        window.toast.success('The drone was updated successfully.');
      })
      .catch((reason) => window.toast.error(reason.title));
  };

  return (
    <PageWrapper fullscreen>
      <div style={{ padding: '50px' }} className="User-form">
        <h2>Drone</h2>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(drone);
          }}
        >
          <Input
            label="Name"
            handleChange={(data) => {
              setDrone({
                ...drone,
                name: data.target.value,
              });
            }}
            handleRef={nameRef}
            inputType={'text'}
          />

          <Select
            onChangeSelect={(data) => {
              setDrone({
                ...drone,
                userId: data.target.value,
              });
            }}
            className="mt-5"
            label="Select User"
            placeholder="Select User to assign to Drone"
            //defaultValue={ {label: `${userRef.current.name}`, value: userRef.current.userId} }
            //ref={userRef}
            options={users?.map((user) => ({ label: `${user.name}: ${user.userId}`, value: user.userId }))}
          />
          <button
            type="submit"
            className="bg-blue rounded-lg px-2 py-1 text-white border-2 border-blue hover:bg-white hover:text-blue transition"
          >
            Save
          </button>
        </form>
      </div>
    </PageWrapper>
  );
}
