import { UserManager, WebStorageStateStore } from 'oidc-client-ts';

const config = {
  authority: process.env.REACT_APP_OIDC_AUTHORITY,
  client_id: process.env.REACT_APP_OIDC_CLIENT_ID,
  redirect_uri: `${window.location.origin}`,
  post_logout_redirect_uri: window.location.origin,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  response_type: 'code',
  scope: 'openid profile email', // add offline_access to enable refresh token flow
  monitorSession: true,
  loadUserInfo: true,
  silent_redirect_uri: `${window.location.origin}`,
  automaticSilentRenew: true,
  includeIdTokenInSilentRenew: true,
  // prompt: 'consent', // enable for refresh token flow
};

const exchangeConfig = { ...config };
exchangeConfig.userStore = new WebStorageStateStore({
  prefix: 'exchange.',
  store: window.localStorage,
});

export const userManager = new UserManager(config);
export const exchangeUserManager = new UserManager(exchangeConfig);

export const onSigninCallback = () => {
  window.history.replaceState({}, document.title, window.location.pathname);
};
