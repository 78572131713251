import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import HorizontalStepper from '../../components/Steppers/HorizonntalStepper';
import DroneAutomaticCheck from '../../components/DroneAutomaticCheck/DroneAutomaticCheck';
import Button from '../../components/Button/Button';
import useWeatherAPI from '../../hooks/useWeatherAPI';
import CenteredMapView from './CenteredMapView';
import useDroneCheckService from '../../hooks/useDroneCheckService';
import GenericService from '../../services/generic.service';
import AlertAdminPopUp from './AlertAdminPopUp';
import useDroneStatus from '../../hooks/useDroneStatus';

// import fakeTelData from '../../services/AutomaticChecks/fakeTelemetryData.json';
/**
 * This component renders the Automatic Checks element
 * which displays the checks done by the drone before starting
 * the mission.
 *
 */

function AutomaticChecks({ isAdmin }) {
  const droneId = localStorage.getItem('droneId');
  const missionId = localStorage.getItem('missionId');
  const { status } = useDroneStatus(droneId, 'full');
  // Hardcoded drone id '6f35df8b-cd64-4509-ae0c-cfb28773eefd' droneName -> New Drone
  // const telemetryData = fakeTelData; // For testing
  const {
    isDroneConnected,
    isBatteryLevelEnough,
    isInternetConnectionLive, // eslint-disable-line
    areChecksPending,
    areHealthChecksFailing,
    isWithinNoFlyZoneState,
    errors,
  } = useDroneCheckService(status, missionId);

  // Fetching API weather data
  const [isWeatherPending, setIsWeatherPending] = useState(true);
  // const [isLoadingWeather, setIsLoadingWeather] = useState(true);
  const [externalConditions, setExternalConditions] = useState([
    ['Acceptable Rain Level', false, true],
    ['Acceptable Wind Speed', false, true],
    ['Clear Visibility', false, true],
  ]);

  const { isRainy, isWindy, isDay, isPending, weatherErrors } = useWeatherAPI(); // eslint-disable-line

  // Third value should be the Pending attribute
  // Drone connection is checked in the useDroneCheckService (reason for no drone pending)
  const softwareChecks = [
    ['Drone Connection', isDroneConnected, !isDroneConnected],
    ['Battery Level', isBatteryLevelEnough, !isBatteryLevelEnough],
    ['Drone Ready', !areHealthChecksFailing, areChecksPending],
    ['Drone Position', !isWithinNoFlyZoneState, isWithinNoFlyZoneState],
  ];

  const [systemConditionsFailed, setSystemConditionsFailed] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const showSystemFailures = useMemo(
    () => !isLoading && systemConditionsFailed && !isWeatherPending,
    [isLoading, systemConditionsFailed, isWeatherPending]
  );

  const navigate = useNavigate();
  const onCancelClick = () => {
    GenericService.FlightReportService.sendDroneCommand(droneId, missionId, 'cancel_take_off', 0, 0, 0, 0);
    navigate('/schedule');
  };

  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const onStartClick = () => {
    setIsPopupVisible(true);
  };

  const onStartConfirmed = (params) => {
    // TODO: here we send prepare instruction, wait a bit, start a mission
    // TODO: Ideally we want to prepare for mission earlier. Maybe when we select drone and mission?

    toast.info('Preparing mission. Please wait...');

    GenericService.MissionService.prepareForMission(missionId, droneId)
      .then(
        (res) =>
          new Promise((resolve) => {
            setTimeout(() => resolve(res), 2000);
          })
      )
      .then((res) => {
        if (!res.isSuccess) {
          console.error('Error preparing for mission:', res.message);
          toast.error('Error preparing for mission');
          return Promise.reject();
        }
        return GenericService.MissionService.startMission(missionId, droneId, params);
      })
      .then((res) => {
        if (!res.success) {
          console.error('Error starting mission:', res.message);
          toast.error('Error starting for mission');
          return Promise.reject();
        }

        toast.success('Mission started');

        console.log('Mission started:', res.flight.flightReportId);
        navigate(`/?droneId=${droneId}`);
        return Promise.resolve();
      })
      .catch((err) => {
        console.error('Error starting mission:', err);
      });
  };

  const handlePopupConfirm = () => {
    setIsPopupVisible(false);
    onStartConfirmed({
      speed: 4,
      photoDelay: 1600,
      rotation: 20,
      pitch: 30,
      liveStream: true,
      allowProcessing: true,
    });
  };

  const handlePopupClose = () => {
    setIsPopupVisible(false);
  };

  // Blocks the "Go Back" browser arrow
  useEffect(() => {
    const disableBackButton = (e) => {
      e.preventDefault();
      window.history.forward();
    };

    // Listen for the popstate event
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', disableBackButton);

    // Clean up to prevent memory leaks
    return () => window.removeEventListener('popstate', disableBackButton);
  }, []);

  // Gets data from drone
  useEffect(() => {
    // console.log('Demo log for fake data refresh - comment me or uncomment me to see results for new data simulation');
    if (!isLoading) {
      setSystemConditionsFailed(false);
    }

    // This is a temp fix to not show admin with no-fly zone errors
    const errorsWithoutFlyzoneErrors = errors.filter((error) => error.title !== 'No-fly zone');

    if (
      errorsWithoutFlyzoneErrors.length > 0 ||
      weatherErrors.length > 0 ||
      areHealthChecksFailing
      // isWithinNoFlyZoneState
    ) {
      setSystemConditionsFailed(true);
      setIsLoading(false);
      return;
    }
    const checkPendingStateSoftwareChecks = (arr) => arr.every((item) => item[2] === true);
    setIsLoading(checkPendingStateSoftwareChecks(softwareChecks));
  }, [status, externalConditions]);

  // This is to apply delay to the weather checks loading
  useEffect(() => {
    if (!isPending) {
      setIsWeatherPending(false);
      // Update external conditions based on weather API response
      setExternalConditions([
        ['Acceptable Rain Level', !isRainy, isPending],
        ['Acceptable Wind Speed', !isWindy, isPending],
        ['Clear Visibility', isDay, isPending],
      ]);
    }
  }, [isRainy, isWindy, isDay, isPending]);

  return (
    <PageWrapper>
      <div className="w-full">
        <h2 className="mt-10 col-span-2">Pre-Flight Checklist</h2>
        <div className="flex flex-col md:flex-row justify-center items-start">
          <div className="flex-1 p-8 pl-0">
            <HorizontalStepper steps={softwareChecks} widthPercentage="100" stepsTitle="Software Inspection" />
            {showSystemFailures && (
              <div className="col-span-1">
                <DroneAutomaticCheck
                  checkName="Software Errors"
                  checkDescription="Testing Description of the check"
                  isLoading={isLoading}
                  isSuccessful={!systemConditionsFailed && errors.length === 0}
                  errors={errors}
                  startCallback={onStartConfirmed}
                />
              </div>
            )}
          </div>
          <div className="flex-1 border-l border-[#cccccc] p-8 pr-0">
            <HorizontalStepper steps={externalConditions} widthPercentage="100" stepsTitle="External Conditions" />
            {showSystemFailures && (
              <div className="col-span-1">
                <DroneAutomaticCheck
                  checkName="Wheather errors"
                  checkDescription="Testing Description of the check"
                  isLoading={isLoading}
                  isSuccessful={!systemConditionsFailed && weatherErrors.length === 0}
                  errors={weatherErrors}
                  startCallback={onStartConfirmed}
                />
              </div>
            )}
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4 mt-10">
          <div className="col-span-1">
            <CenteredMapView missionId={missionId} />
          </div>
          {showSystemFailures ? (
            <>
              <div className="col-span-1">
                <div className="flex h-full w-full justify-center	items-center">
                  <div>
                    <h3 className="font-bold text-3xl mb-5 text-center">Checks failed</h3>
                    <div className="flex gap-4">
                      <Button text="CANCEL" buttonType="cancelOnly" onClick={onCancelClick} />
                      {isAdmin && isDroneConnected ? (
                        <Button text="ADMIN START" buttonType="start-admin" onClick={onStartClick} />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              {isPopupVisible && <AlertAdminPopUp onConfirm={handlePopupConfirm} onClose={handlePopupClose} />}
            </>
          ) : (
            <div className="col-span-1">
              <DroneAutomaticCheck
                checkName="Safety checks"
                checkDescription="Testing Description of the check"
                isLoading={isLoading || isWeatherPending}
                isSuccessful={!systemConditionsFailed}
                startCallback={onStartConfirmed}
              />
            </div>
          )}
        </div>
      </div>
    </PageWrapper>
  );
}

export default AutomaticChecks;
