import { useRef, useState } from 'react';
import useDroneConnection from './useDroneConnection';

const useDroneTelemetry = (droneId) => {
  const [status, setStatus] = useState(null);
  const [uploadStatus, setUploadStatus] = useState(null);
  const statusTimeoutRef = useRef(null);

  const [location, setLocation] = useState(null);
  const locationTimeoutRef = useRef(null);

  const [hardware, setHardware] = useState(null);
  const hardwareTimeoutRef = useRef(null);

  const updateStatusTimeout = () => {
    if (statusTimeoutRef.current) {
      clearTimeout(statusTimeoutRef.current);
    }
    statusTimeoutRef.current = setTimeout(() => {
      setStatus(null);
      setUploadStatus(null);
    }, 2000); // Set the drone to offline if no telemetry data is received within 5 seconds
  };

  const updateLocationTimeout = () => {
    if (locationTimeoutRef.current) {
      clearTimeout(locationTimeoutRef.current);
    }
    locationTimeoutRef.current = setTimeout(() => {
      setLocation(null);
    }, 2000); // Set the drone to offline if no telemetry data is received within 5 seconds
  };

  const updateHardwareTimeout = () => {
    if (hardwareTimeoutRef.current) {
      clearTimeout(hardwareTimeoutRef.current);
    }
    hardwareTimeoutRef.current = setTimeout(() => {
      setHardware(null);
    }, 2000); // Set the drone to offline if no telemetry data is received within 5 seconds
  };

  const handler = (json) => {
    const dataType = json.type;
    switch (dataType) {
      case 'DroneStatus':
        setStatus(json.data);
        updateStatusTimeout();
        break;
      case 'DroneLocation':
        setLocation(json.data);
        updateLocationTimeout();
        break;
      case 'DroneHardwareStatus':
        setHardware(json.data);
        updateHardwareTimeout();
        break;
      case 'AssetUploadStatus':
        setUploadStatus(json.data);
        updateStatusTimeout();
        break;
      default:
        console.log('Unknown data type: ', dataType);
    }
  };

  const { telemetryData } = useDroneConnection(droneId, 'telemetry', handler);
  return { status, location, hardware, uploadStatus, online: telemetryData !== null };
};

export default useDroneTelemetry;
