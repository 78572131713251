import React, { useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import homeMockup from '../../assets/images/drone-home-macbook.png';
import ExchangeAuthService from '../../services/Authentication/exchange.service';
import UserService from '../../services/Authentication/user.service';
import './PretectedApp.scss';
import { exchangeUserManager } from '../../config.ts';

export default function ProtectedApp({ children }) {
  const auth = useAuth();
  const [registered, setRegistered] = useState(false);

  const onSilentRenew = (user) =>
    exchangeUserManager
      .storeUser(user)
      .then(() =>
        exchangeUserManager.signinSilent({
          scope: 'openid profile email offline_access',
          state: { skipUpdate: true },
        })
      )
      .catch((e) => {
        console.error(e);
      });

  useEffect(() => {
    if (auth.isAuthenticated && localStorage.getItem('user_id')) {
      // If user is authenticated and user_id is set in session storage,
      // then we are good to continue
      setRegistered(true);
    }
    const onUserSignedIn = (user) => {
      // Get current user or register new user
      // then set user_id in session storage, so that other services can use it
      UserService.getCurrentUser(user)
        .then((dashboardUser) => {
          if (dashboardUser) {
            localStorage.setItem('user_id', dashboardUser.userId);
            setRegistered(true);
            return Promise.resolve();
          }
          console.error('Error getting user');
          return auth.removeUser();
        })
        .catch((e) => {
          console.error(e);
        });
      // Additionally, if we run on a drone controller/ExchangeAuth,
      // pass down user access token to the remote
      ExchangeAuthService.isAvailable()
        .then((success) => {
          if (success) {
            return onSilentRenew(user).then((exchangeUser) => ExchangeAuthService.exchangeLogin(exchangeUser));
          }
          return Promise.resolve();
        })
        .catch((e) => {
          console.error(e);
        });
    };
    auth.events.addUserLoaded(onUserSignedIn);

    const onUserLoggedOut = () => {
      localStorage.removeItem('user_id');
      setRegistered(false);
    };
    auth.events.addUserUnloaded(onUserLoggedOut);
  }, [auth]);

  // This part force-redirects user to login page if not authenticated
  useEffect(() => {
    if (!auth.isAuthenticated && !auth.isLoading) {
      auth.signinRedirect();
    }
  }, [auth]);

  if (auth.isAuthenticated && registered) {
    return children;
  }

  return (
    <div className="login">
      <div className="left">
        <img alt="mockup" src={homeMockup} />
      </div>
      <div className="right">
        <div className="inner">
          <h3>Login</h3>
          {auth.isAuthenticated ? (
            <p>Loading...</p>
          ) : (
            <p>You need to sign in to access this page. Click the button below to sign in with your credentials.</p>
          )}

          <div className="button-wrapper">
            <button type="button" className="bg-blue rounded text-white px-4" onClick={() => auth.signinRedirect()}>
              Sign in
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
