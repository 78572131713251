import axios from 'axios';
import { User } from 'oidc-client-ts';

/* eslint-disable */
// TODO: someone has to really configure eslint properly because this is driving me crazy

// This is a  simple service that checks if frontend is opened on the device running remoteapp,
// which runs simple HTTP server on localhost:8080

const instance = axios.create({
  baseURL: `http://localhost:8080`,
});

const isAvailable = () => {
  return instance.get('/health')
    .then((res) => {
      return res.status === 200;
    })
    .catch((err) => false);
};

const exchangeLogin = (user : User) => {
  return instance.post('/login', {
    idToken: user.id_token,
    accessToken: user.access_token,
    refreshToken: user.refresh_token,
    tokenEndpoint: process.env.REACT_APP_OIDC_AUTHORITY + '/oauth/v2/token'
  }).catch((err) => Promise.reject(err));
};

const exchangeLogout = () => {
  return instance.post('/logout')
    .catch((err) => Promise.reject(err));
};

const ExchangeAuthService = {
  isAvailable,
  exchangeLogin,
  exchangeLogout,
};

export default ExchangeAuthService;
