// eslint-disable-next-line
import { HubConnectionBuilder, HubConnection, LogLevel } from '@microsoft/signalr';
import { useEffect, useState, useRef } from 'react';

/**
 * Custom hook to establish a connection to the SignalR hub and receive telemetry data from the drone.
 * @param droneId The ID of the drone to connect to
 * @param channel The channel to subscribe to. Can be either 'telemetry' or 'logs'
 * @param handler The function to call when telemetry data is received
 * @returns {{logLine: unknown, connection: HubConnection, telemetryData: unknown, closeConnection: ((function(): Promise<void>)|*)}}
 */
const useDroneConnection = (droneId, channel = 'telemetry', handler = null) => {
  const [connection: HubConnection, setConnection] = useState(null);
  const [telemetryData, setTelemetryData] = useState(null);
  const [telemetryDataType, setTelemetryDataType] = useState(null);
  const [logLine, setLogLine] = useState(null);
  const telemetryTimeoutRef = useRef(null);

  const onTelemetryDataReceived = (data) => {
    const json = JSON.parse(data);
    setTelemetryData(json.data);
    setTelemetryDataType(json.type);

    if (handler) {
      handler(json);
    }

    // Clear the previous timeout and set a new one
    if (telemetryTimeoutRef.current) {
      clearTimeout(telemetryTimeoutRef.current);
    }
    telemetryTimeoutRef.current = setTimeout(() => {
      setTelemetryData(null);
    }, 2000); // Set the drone to offline if no telemetry data is received within 5 seconds
  };

  const onLogDataReceived = (data) => {
    setLogLine(JSON.parse(data));
  };

  const onExit = async (conn) => {
    await conn.stop();
  };

  const closeConnection = async () => {
    if (connection) {
      await connection.stop();
    }
  };

  const onConnected = () => {
    if (channel === 'telemetry') {
      connection.invoke('StartSendingTelemetry', droneId);
      connection.on('DroneTelemetry', onTelemetryDataReceived);
    } else if (channel === 'logs') {
      connection.invoke('StartSendingLogs', droneId);
      connection.on('DroneLogs', onLogDataReceived);
    } else {
      console.error(`Invalid channel: ${channel}`);
    }
  };

  useEffect(() => {
    if (droneId === '') {
      return () => closeConnection();
    }

    const newConnection = new HubConnectionBuilder()
      .withUrl(`${process.env.REACT_APP_API_URL}/dronews`)
      .withAutomaticReconnect()
      .configureLogging(LogLevel.Warning)
      .build();
    setConnection(newConnection);
    return () => onExit(newConnection);
  }, [droneId]);

  useEffect(() => {
    async function start() {
      if (connection) {
        await connection.start();
        connection.onreconnected(onConnected);
        onConnected();
      }
    }
    start();
  }, [connection]);

  return { connection, telemetryData, telemetryDataType, logLine, closeConnection };
};

export default useDroneConnection;
