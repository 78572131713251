import React from 'react';

// Import FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useAuth } from 'react-oidc-context';
import ExchangeAuthService from '../../../../services/Authentication/exchange.service';

export default function Logout() {
  const auth = useAuth();

  return (
    <div className="Weather">
      <FontAwesomeIcon
        onClick={() => {
          ExchangeAuthService.isAvailable().then((success) => {
            if (success) {
              ExchangeAuthService.exchangeLogout().catch((e) => {
                console.error(e);
              });
            }
            // auth.removeUser(); // This will destroy current session, but user will still be able to login again
            auth.signoutRedirect(); // This will log out user from OIDC provider
          });
        }}
        icon={regular('right-from-bracket')}
      />
    </div>
  );
}
