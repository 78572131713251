import { User } from 'oidc-client-ts';
import api from '../api';

const getUsers = () =>
  api
    .get('/users')
    .then((res) => res?.data)
    .catch((err) => Promise.reject(err.response.data));

const getUser = (user) =>
  api
    .get(`/users/${user.userId}`, user)
    .then((res) => res)
    .catch((err) => Promise.reject(err.response.data));

const getCurrentUser = () =>
  api
    .get(`/users/me`)
    .then((res) => res?.data)
    .catch((err) => Promise.reject(err.response.data));
const updateUser = (user) =>
  api
    .put(`/users/${user.userId}`, user)
    .then((res) => res)
    .catch((err) => Promise.reject(err.response.data));

const checkRole = (user: ?User, role: string) =>
  user !== undefined &&
  user.profile !== undefined &&
  user.profile.groups !== undefined &&
  user.profile.groups.includes(role);

const checkAdmin = (user: ?User) => checkRole(user, 'admin');

const UserService = {
  getUser,
  getUsers,
  updateUser,
  getCurrentUser,
  checkRole,
  checkAdmin,
};

export default UserService;
