/* eslint-disable class-methods-use-this */
class DroneCheckService {
  async getSoftwareChecks(telemetryData) {
    if (!telemetryData) {
      return {
        isDroneConnected: false,
        isBatteryLevelEnough: false,
        isInternetConnectionLive: false,
        areChecksPending: false,
        areHealthChecksFailing: true,
        droneErrors: [{ title: 'Connection failed', description: 'There is no drone connected' }],
      };
    }

    try {
      const isDroneConnected = this.isDroneConnected(telemetryData);
      const isBatteryLevelEnough = this.isBatteryLevelEnough(telemetryData);
      const isInternetConnectionLive = this.isInternetConnectionLive(telemetryData);
      const areChecksPending = this.areChecksPending(telemetryData);
      const areHealthChecksFailing = this.areHealthChecksFailing(telemetryData);
      const droneErrors = this.getErrorMessages(
        isDroneConnected,
        isBatteryLevelEnough,
        isInternetConnectionLive,
        areHealthChecksFailing,
        telemetryData
      );

      return {
        isDroneConnected,
        isBatteryLevelEnough,
        isInternetConnectionLive,
        areChecksPending,
        areHealthChecksFailing,
        droneErrors,
      };
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  isDroneConnected(telemetryData) {
    return telemetryData != null && telemetryData.status?.connected;
  }

  isBatteryLevelEnough(telemetryData) {
    return telemetryData.hardwareStatus !== null && telemetryData.hardwareStatus.batteryLevel > 20;
  }

  isInternetConnectionLive(telemetryData) {
    return telemetryData.status !== null && telemetryData.status.connected;
  }

  areChecksPending(telemetryData) {
    return !(
      telemetryData.status !== null &&
      telemetryData.status.connected &&
      telemetryData.status.health &&
      telemetryData.status.hasGps
    );
  }

  areHealthChecksFailing(telemetryData) {
    // health should only be true if all health checks are passing
    return !(telemetryData.status !== null && telemetryData.status.health);
  }

  getHealthChecksErrorMessages(telemetryData) {
    const { statusMessage, connectionMessage, gpsMessage } = telemetryData.status;
    const messages = [];

    if (statusMessage !== null) {
      messages.push({ title: 'Health', description: statusMessage });
    }

    if (!connectionMessage) {
      messages.push({ title: 'Connection', description: 'No connection to the drone' });
    }

    if (!gpsMessage) {
      messages.push({ title: 'GPS', description: 'No GPS signal' });
    }
    return messages;
  }

  getErrorMessages(
    isDroneConnected,
    isBatteryLevelSufficient,
    isInternetConnectionStable,
    areHealthChecksFailing,
    telemetryData
  ) {
    if (!isDroneConnected) {
      return [{ title: 'Connection failed', description: 'There is no drone connected' }];
    }
    const errors = [];
    if (!isBatteryLevelSufficient) {
      errors.push({
        title: 'Insufficient Battery Level',
        description: 'The battery level is too low for safe operation',
      });
    }
    if (!isInternetConnectionStable) {
      errors.push({
        title: 'Unstable Internet Connection',
        description: 'No or unstable Internet connection',
      });
    }
    if (areHealthChecksFailing) {
      const healthErrors = this.getHealthChecksErrorMessages(telemetryData);
      errors.push(...healthErrors);
    }

    return errors;
  }
}

export default DroneCheckService;
