import api from '../api';
import apps from '../../Apps.json';

const GetAllApps = () =>
  api
    .get(`/apps`)
    .then((res) => res?.data)
    .catch((err) => Promise.reject(err.response.data));

const GetAllStaticApps = () => apps;

const GetFavoriteApps = () =>
  api
    .get(`/favorite-apps`)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err.response.data));

const AddFavoriteApp = (id) =>
  api
    .post(`/favorite-apps`, { appId: id })
    .then((res) => res.data)
    .catch((err) => Promise.reject(err.response.data));

const DeleteFavoriteApp = (id) =>
  api
    .delete(`/favorite-apps/${id}`)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err.response.data));

const AppStoreService = {
  GetAllApps,
  GetAllStaticApps,
  GetFavoriteApps,
  AddFavoriteApp,
  DeleteFavoriteApp,
};

export default AppStoreService;
