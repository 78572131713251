/* eslint-disable */

import React, {useEffect, useState} from 'react';
import '../shared.scss';
import './TestPage.scss';
import {useRef} from 'react';
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import DroneDropdown from "./DroneDropdown";
import useDroneConnection from '../../hooks/./useDroneConnection';

export default function TestPage() {
  const [dataGatheringStatus, setDataGatheringStatus] = useState(0);
  const [longitude, setLongitude] = useState(null);
  const [latitude, setLatitude] = useState(null);

  const [selectedDroneId, setSelectedDroneId] = useState('');
  const {logLine, closeConnection} = useDroneConnection(selectedDroneId, 'logs');

  const [scrollingStopped, setScrollingStopped] = useState(false);
  const [savedDebugData, setSavedDebugData] = useState([]);
  const [textareaContent, setTextareaContent] = useState('');
  const textareaRef = useRef(null);

  const handleDroneSelection = async (id) => {
    if (selectedDroneId !== id) {
      await closeConnection();
      setTextareaContent('');
      setSelectedDroneId(id);
    }
  };

  const toggleScrolling = () => {
    setScrollingStopped(prevState => !prevState);

    if (!scrollingStopped) {
      setSavedDebugData([]);
    } else {
      const newDebugData = savedDebugData.filter(data => !textareaContent.includes(data));
      setTextareaContent(prevContent => prevContent + newDebugData.join('\n') + '\n');

      textareaRef.current.scrollTop = textareaRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    if (logLine !== null) {
      if (!scrollingStopped) {
        setTextareaContent(prevData => prevData.includes(logLine.message) ? prevData : prevData + logLine.message + '\n');
        textareaRef.current.scrollTop = textareaRef.current.scrollHeight;
      } else {
        setSavedDebugData(prevData => [...prevData, logLine.message]);
      }
    }
  }, [logLine, scrollingStopped]);

  const clearTextarea = () => {
    if (textareaRef.current) {
      setTextareaContent('');
      setScrollingStopped(false);
    }
  };

  return (
    <PageWrapper fullscreen>
      <div style={{padding: '100px'}} className="TestPage">
        <h2>Drone Testing</h2>
        <br/>

        <div className="group">
          <div className="group-item">
            <label htmlFor="drones">Select Drone: </label>
            <DroneDropdown onSelect={handleDroneSelection}/>
          </div>
          <br/>

          <div className="group-item">
            <label htmlFor="longitude">Longitude: </label>
            <input
              type="text"
              onChange={(e) => setLongitude(e.target.value)}
              placeholder="Longitude..."
              className="border-2 border-grey px-2 focus:outline-none"
            />
          </div>
          <br/>

          <div className="group-item">
            <label htmlFor="latitude">Latitude: </label>
            <input
              type="text"
              onChange={(e) => setLatitude(e.target.value)}
              placeholder="Latitude..."
              className="border-2 border-grey px-2 focus:outline-none"
            />
          </div>
          <br/>
          <button
            className="bg-blue rounded-lg px-2 py-1 text-white border-2 border-blue hover:bg-white hover:text-blue transition"
          >
            Send Coords
          </button>
          <br/>
        </div>

        <div className="group">
          <div className="group-item">
            <label htmlFor="datagathering">Data Gathering Command</label>
            {dataGatheringStatus === 0 ? (
              <button
                className="bg-blue rounded-lg px-2 py-1 text-white border-2 border-blue hover:bg-white hover:text-blue transition block"
              >
                StartDataGathering
              </button>
            ) : (
              <button
                className="bg-blue rounded-lg px-2 py-1 text-white border-2 border-blue hover:bg-white hover:text-blue transition block"
              >
                StopDataGathering
              </button>
            )}
          </div>
        </div>

        <label>Debug messages</label>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <button
            className="bg-blue rounded-lg px-2 py-1 text-white border-2 border-blue hover:bg-white hover:text-blue transition block"
            onClick={clearTextarea}
          >
            Clear
          </button>
          <button
            onClick={toggleScrolling}
            className="bg-blue rounded-lg px-2 py-1 text-white border-2 border-blue hover:bg-white hover:text-blue transition block"
            style={{marginLeft: '10px'}}
          >
            {scrollingStopped ? 'Start Scrolling' : 'Stop Scrolling'}
          </button>
        </div>

        <textarea
          id="debugMessagesTextArea"
          name="debugMessages"
          rows="20"
          cols="100"
          ref={textareaRef}
          value={textareaContent}
          className={`border-2 border-grey px-2 focus:outline-none rounded-lg mt-5 ${
            scrollingStopped ? 'no-scroll' : ''
          }`}
          style={{resize: "none"}}
        ></textarea>
      </div>
    </PageWrapper>
  );
}
