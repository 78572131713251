import React, { useContext } from 'react';
import './DroneBar.scss';
import { motion } from 'framer-motion';
import DroneCard from '../../../../components/Card/DroneCard/DroneCard';
import { DashboardContext } from '../../Dashboard';

export default function DroneBar({ drones }) {
  const { selectedDrone, setSelectedDrone } = useContext(DashboardContext);
  return (
    <div className="grid grid-flow-col auto-cols-max drone-bar">
      {drones !== undefined &&
        drones.map((drone) => (
          <div
            key={drone.droneId}
            role="none"
            onClick={() => {
              setSelectedDrone(drone);
            }}
            className={`${selectedDrone !== null && selectedDrone.droneId === drone.id && 'to-be-implemented:)'}`}
          >
            <motion.div animate={{ y: selectedDrone?.droneId === drone.droneId ? -30 : 0 }}>
              <DroneCard drone={drone} />
            </motion.div>
          </div>
        ))}
    </div>
  );
}
