import api from '../api';

const getUserDrones = (userId) =>
  api
    .get(`/drones/user/${userId}`)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err.response.data));

const getCurrentUserDrones = () => getUserDrones(localStorage.getItem('user_id'));

const getAllDrones = () =>
  api
    .get('/drones')
    .then((res) => res.data)
    .catch((err) => Promise.reject(err.response.data));

const updateDrone = (drone) =>
  api
    .put(`/drones/${drone.droneId}`, drone)
    .then((res) => res)
    .catch((err) => Promise.reject(err.response.data));

const getDrone = (id) =>
  api
    .get(`/drones/${id}/`)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err.response.data));

const createDrone = (drone) =>
  api
    .post(`/drones/`, drone)
    .then((res) => res)
    .catch((err) => Promise.reject(err.response.data));

const deleteDrone = (id) => {
  api
    .delete(`/drones/${id}/`)
    .then((res) => res)
    .catch((err) => Promise.reject(err.response.data));
};

const getDroneStatus = (id) =>
  api
    .get(`/drones/status/${id}/`)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err.response.data));

const getDroneHealth = (id) =>
  api
    .get(`/drones/health/${id}/`)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err.response.data));

const getDroneLocation = (id) =>
  api
    .get(`/drones/location/${id}/`)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err.response.data));

const DroneService = {
  getUserDrones,
  getDrone,
  getCurrentUserDrones,
  getAllDrones,
  updateDrone,
  createDrone,
  deleteDrone,
  getDroneStatus,
  getDroneHealth,
  getDroneLocation,
};

export default DroneService;
